// Classnames
export const classNames = (...args) =>
  args
    .reduce((classesArray, arg) => {
      const argType = typeof arg;

      // String or Number
      if (argType === 'string' || argType === 'number') {
        classesArray.push(arg);
      }

      // Object
      if (argType === 'object') {
        Object.entries(arg).forEach(([key, value]) => {
          if (value) classesArray.push(key);
        });
      }

      return classesArray;
    }, [])
    .join(' ');

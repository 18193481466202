import React from 'react';
import Img from 'gatsby-image';
import './Image.scss';

// TODO FIX MAKE MORE CLEAR

export const Image = props => {
  const { image, className = '' } = props;
  if (!image) return <div className={`gatsby-image placeholder ${className}`} />;

  const imageFluid = image.localFile && image.localFile.childImageSharp && image.localFile.childImageSharp.fluid;

  if (imageFluid) {
    return <Img className={`gatsby-image ${className}`} fluid={imageFluid} alt={image.alt || ''} />;
  }

  if (image.url) {
    return (
      <div className={`gatsby-image ${className}`}>
        <img src={image.url} alt={image.alt || ''} />
      </div>
    );
  }
  return <div className={`gatsby-image placeholder ${className}`} />;
};

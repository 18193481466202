import React from 'react';
import { Wrapper } from 'components/Layout';
import { Logo } from 'components/Elements';
import './Header.scss';

export const Header = () => (
  <header className="header">
    <Wrapper className="header-wrapper">
      <Logo />
    </Wrapper>
  </header>
);

export const loop = (items, components) => {
  if (!items || !components) return null;
  if (Array.isArray(items)) {
    return items.map((item, index) => components({ key: index, ...item }));
  }
  if (typeof items === 'object') {
    return Object.keys(items).map((key, index) => components({ key: index, ...items[key] }));
  }
  return null;
};

import React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Icon } from 'components/Elements';
import './Link.scss';

export const Link = props => {
  const { children, className, to, title, text, icon, large, fill, target = '_blank' } = props;
  let classNames = 'link';
  if (className) classNames += ` ${className}`;
  if (fill) classNames += ` fill`;
  if (large) classNames += ` large`;
  if (icon) classNames += ` icon`;
  const isExternal = (to && to.indexOf('http') !== -1) || (to && to[0] === '#');
  if (isExternal) {
    return (
      <a href={to} className={classNames} title={title || null} target={target} rel="nofollow noopener noreferrer">
        {children}
        {text}
        {icon ? <Icon title={icon} /> : null}
      </a>
    );
  }
  return (
    <GatsbyLink to={to} className={classNames} title={title || null}>
      {children}
      {text}
      {icon ? <Icon title={icon} /> : null}
    </GatsbyLink>
  );
};

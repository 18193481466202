import React from 'react';
import { classNames, toSnakeCase } from 'helpers';
import * as Icons from './Icons';
import './Icon.scss';

export const Icon = ({ title, className }) => {
  const classes = classNames('icon', className);
  const Icon = Icons[toSnakeCase(title)];
  return <div className={classes}>{Icon ? <Icon /> : <Icons.Default />}</div>;
};

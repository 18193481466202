import React, { useState } from 'react';
import { formatDate, classNames } from 'helpers';
import { Logo, Icon, Check } from 'components/Elements';
import './Coupon.scss';

export const Coupon = props => {
  const {
    amount,
    showNotification,
    created,
    metadata,
    qrCode,
    fields,
    status,
    setStatus,
    transactionId,
    placeholders,
  } = props;
  const { date } = created ? formatDate(new Date(created * 1000)) : formatDate();
  const voucherAmount = (metadata && metadata.amountLeft) || amount;
  return (
    <>
      <div className="coupon">
        <div className="coupon-left">
          {qrCode ? (
            <a href={`/?voucher=${transactionId}`} target="_blank">
              <img className="coupon-qrcode" src={qrCode} />
            </a>
          ) : (
            <Logo light />
          )}
          <Check className="coupon-check" animated={(status && status.code) === 'success'} />
        </div>

        <div className="coupon-right">
          <div className="coupon-details">
            <div className="coupon-info">
              <div className="coupon-amount">{(voucherAmount && `A$${voucherAmount / 100}`) || 'A$0.00'}</div>
              <div className="coupon-validity">Valid for 3 years</div>
            </div>

            <div className="coupon-date">
              <span className="label">DATE</span>
              {date}
            </div>
          </div>

          <div className="coupon-contact">
            <div>
              <span className="label">NAME</span>
              <span className={classNames({ isPlaceholder: fields && !fields.name })}>
                {(fields && fields.name) || (placeholders && placeholders.name)}
              </span>
            </div>
            <div>
              <span className="label">EMAIL</span>
              <span className={classNames({ isPlaceholder: fields && !fields.email })}>
                {(fields && fields.email) || (placeholders && placeholders.email)}
              </span>
            </div>
          </div>
        </div>
      </div>

      {showNotification && (
        <div className={classNames('coupon-notification', { success: (status && status.code) === 'success' })}>
          <div className="coupon-notification-wrapper">
            <div className="coupon-notification-message">
              Your transaction was successfull. An email was sent to {fields && fields.email}. You reference number is "
              <span>{transactionId}"</span>.
            </div>
            {/* <div className="coupon-close" type="button" onClick={() => setStatus('paid', '')}>
            <Icon title="close" />
          </div> */}
          </div>
        </div>
      )}
    </>
  );
};

import React from 'react';
import { Header, Footer } from 'components/Layout';
import { SEO } from 'components/Elements';
import '../../../tools/sass/global/styles.scss';
import './Page.scss';
import 'typeface-open-sans';
import 'typeface-unica-one';

export const Page = props => {
  const { children, location, seoData = {} } = props;
  const { meta_title: metaTitle, meta_description: metaDesc, open_graph_image: ogImage, schema } = seoData;

  const seo = {
    title: metaTitle && metaTitle.text,
    desc: metaDesc && metaDesc.text,
    banner: ogImage && ogImage.localFile && ogImage.localFile.childImageSharp.fixed.src,
    schema: schema && schema.text,
  };

  return (
    <>
      <Header location={location} />
      <main>
        <SEO {...seo} />
        {children}
      </main>
      {/* <Footer /> */}
    </>
  );
};

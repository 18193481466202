export const validate = (name, value) => {
  let error;

  if (name === 'name' || name === 'recipientName') {
    const regex = /^[a-zA-Z-,]+(\s{0,1}[a-zA-Z-, ])*$/;
    const message = 'Please enter a valid name.';
    if (!regex.test(value)) error = message;
  }

  if (name === 'email' || name === 'recipientEmail') {
    const regex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const message = 'Please enter a valid email address.';
    if (!regex.test(value)) error = message;
  }

  return error;
};

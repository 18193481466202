import React from 'react';
import { classNames } from 'helpers';
import { Icon } from 'components/Elements';
import './Notification.scss';

export const Notification = ({ className, show, code, message, setStatus, close }) => (
  <div className={classNames('notification', { hide: !message }, code)}>
    <div className="notification-wrapper">
      <div className="notification-message">{message}</div>
      {close && (
        <div className="notification-close" type="button" onClick={() => setStatus()}>
          <Icon title="close" />
        </div>
      )}
    </div>
  </div>
);

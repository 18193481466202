import React from 'react';
import { Icon } from 'components/Elements';
import './Heading.scss';

export const Heading = ({ icon, title }) => (
  <div className="heading">
    <Icon title={icon} />
    {title}
  </div>
);

import React from 'react';
import { classNames } from 'helpers';
import CheckMark from '../../../assets/icons/Check.svg';
import './Check.scss';

export const Check = ({ animated, className }) => {
  const classes = classNames(className, { active: animated });
  const classesSVG = classNames({ animated });
  return (
    <div className={classes}>
      <CheckMark className={classesSVG} />
    </div>
  );
};

import React from 'react';
import { Image, Link } from 'components/Elements';
import { siteName } from '../../../../config/website';
import LogoImg from '../../../assets/images/Logo.png';
import LogoImgLight from '../../../assets/images/Logo_Light.png';
import './Logo.scss';

export const Logo = ({ light }) => (
  <Link className="logo" to="https://indigodoublebay.com/">
    <img src={light ? LogoImgLight : LogoImg} alt={siteName} />
  </Link>
);

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Page } from 'components/Layout';
import { Icon, Notification } from 'components/Elements';
import { TextField } from 'components/Form';
import { Coupon } from '../Coupon/Coupon';
import { VoucherHistory } from '../VoucherHistory/VoucherHistory';
import './VouchersVerify.scss';

export const VouchersVerify = ({ transactionId }) => {
  const [transaction, setTransaction] = useState({});
  const [status, setStatus] = useState({ code: '', message: '' });
  const [mode, setMode] = useState(null);
  const [fields, setFields] = useState({});

  // Verify Charge
  const verifyCharge = async () => {
    try {
      setStatus({ code: 'waiting' });
      const { data } = await axios.post('/.netlify/functions/verify', transactionId);
      setTransaction(data);
      const amountLeft = data && data.metadata && data.metadata.amountLeft;
      if (amountLeft > 0) setStatus({ code: 'success' });
      setStatus({ code: 'expired' });
    } catch (error) {
      setStatus({ code: 'error', message: error.response.data });
    }
  };

  // Update Charge
  const updateCharge = async () => {
    try {
      setStatus({ code: 'waiting' });
      const body = JSON.stringify({ transactionId, passcode: fields.passcode, amountSpent: fields.amountSpent * 100 });
      const { data } = await axios.post('/.netlify/functions/update', body);
      setTransaction(data);
      setMode();
      setStatus({ code: 'success', message: 'The voucher was successfully updated.' });
    } catch (error) {
      setStatus({ code: 'error', message: error.response.data });
    }
  };

  useEffect(() => {
    verifyCharge(transactionId);
  }, []);

  const onChangeHandler = (name, value) => {
    setFields({ ...fields, [name]: value });
  };

  // Transactions History
  const history = <VoucherHistory transaction={transaction} />;

  // Update balance form
  const update = (
    <div className="vouchers-update">
      <TextField name="amountSpent" type="number" placeholder="Amount spent" onChange={onChangeHandler} />
      <TextField name="passcode" type="password" placeholder="Passcode" onChange={onChangeHandler} />
      <button onClick={() => updateCharge(transactionId, fields)} disabled={(status && status.code) === 'waiting'}>
        {(status && status.code) === 'waiting' ? <Icon className="loader" title="loader" /> : 'Charge'}
      </button>
    </div>
  );

  return (
    <div className="vouchers-verify">
      <Coupon
        {...transaction}
        status={status}
        transactionId={transactionId}
        fields={transaction && transaction.metadata}
      />
      <div className="vouchers-verify-controls">
        {mode && (
          <button onClick={() => setMode()}>
            <Icon title="close" />
          </button>
        )}
        {!mode && (
          <button className="history" onClick={() => setMode('history')}>
            See history
          </button>
        )}
        {!mode && (
          <button onClick={() => setMode('update')}>
            <Icon title="settings" />
          </button>
        )}
      </div>
      {mode === 'history' && history}
      {mode === 'update' && update}
      <Notification code={status && status.code} message={status && status.message} setStatus={setStatus} close />
    </div>
  );
};

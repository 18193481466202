import React from 'react';
import { formatDate, classNames } from 'helpers';
import './VoucherHistory.scss';

const Items = ({ transactions }) => {
  if (!transactions) return null;
  return transactions.map((transaction, index) => {
    const { a: amount, s: amountSpent, d: dateString } = transaction;
    const { date, time } = formatDate(new Date(dateString));
    return (
      <div className="voucher-history-items" key={index}>
        <div className="balance">
          <div className="spent">{`- $${amountSpent / 100}`}</div>
          <div>{`Balance $${amount / 100}`}</div>
        </div>
        <div className="date">
          <div>{date}</div>
          <div>{time}</div>
        </div>
      </div>
    );
  });
};

export const VoucherHistory = ({ transaction, className }) => {
  const { amount, created } = transaction;
  const { date, time } = formatDate(new Date(created * 1000));
  const transactions = transaction.metadata && transaction.metadata.history && JSON.parse(transaction.metadata.history);
  const transactionsCount = (transactions && transactions.length) || 0;
  return (
    <div className={classNames('voucher-history', className)}>
      <div className="voucher-history-initial">
        <div className="voucher-history-initial-balance">
          <div className="voucher-history-initial-amount">{amount ? `A$${amount / 100}` : 'A$0.00'}</div>
          <div>Opening balance</div>
        </div>
        <div className="voucher-history-initial-date">
          {created && <div>{date}</div>}
          {created && <div>{time}</div>}
        </div>
      </div>
      {transactionsCount > 0 && (
        <div className="voucher-history-transactions">Showing last {transactionsCount} transactions</div>
      )}
      <Items transactions={transactions} />
    </div>
  );
};
